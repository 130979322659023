import { graphql, useStaticQuery } from "gatsby";

const Color = () => {

    const colorSecondaryQuery = useStaticQuery(graphql`
        query colorSecondaryQuery {
            allWordpressAcfPages(filter: {wordpress_id: {eq: 2}}) {
                nodes {
                    id
                    acf {
                        ustawienia_kolor_dodatkowy
                    }
                }
            }
        }
    `)

    const acf = colorSecondaryQuery.allWordpressAcfPages.nodes[0].acf

    return acf.ustawienia_kolor_dodatkowy
};
  
export default Color;