import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Image from "./image"
import { FlexBox, Button, Title3, Text, styles } from "./styles"
import parse from "html-react-parser"

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 35px 0;

  .gatsby-image-wrapper {
    height: 280px;
    margin-bottom: 15px;
    background-color: ${styles.colorPrimary};
  }

  @media (min-width: 768px) {
    padding: 35px;
    border: 1px solid transparent;
    transition: border-color 0.3s ease;

    &:hover {
      border-color: ${styles.colorSecondary};
    }
  }
`
const Content = styled.div`
  padding: 0 15px;

  @media (min-width: 768px) {
    padding: 0;
  }
`

const Date = styled.span`
  color: ${styles.colorSecondary};
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
  padding-right: 10px;
`

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 84px;
  padding: 9px 0;

  h3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const TextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 160px;
  padding: 26px 0;

  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const ReadMoreBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: #fff;
    background-color: ${styles.colorPrimary};
    margin: 0 auto;
    white-space: nowrap;
  }
`

let Categories = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  & > span {
    color: ${styles.colorSecondary};
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    margin-left: 10px;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: ${styles.colorPrimary};
      }
    }
  }
`

const Article = ({
  date,
  title,
  short,
  excerpt,
  slug,
  categories,
  categoriesSlug,
  image,
}) => {
  const formatedDate = formatDate({ date })
  // const formatedText = formatText(short)
  const formatedCategories = getCategories(categories, categoriesSlug)
  return (
    <>
      <Box>
        <Image filename={image} />
        <Content>
          <FlexBox justify={"space-between"} mb={"20px"}>
            <Date>{formatedDate}</Date>
            <Categories>{formatedCategories}</Categories>
          </FlexBox>
          <TitleBox>
            <Title3>{title}</Title3>
          </TitleBox>
          <TextBox>
            <Text>{short}</Text>
          </TextBox>
          <ReadMoreBox>
            <Button to={`posts/${slug}`}>Czytaj dalej</Button>
          </ReadMoreBox>
        </Content>
      </Box>
    </>
  )
}

export default Article

// function formatText(text) {
//   text = text
//     .replace("<p>", "")
//     .replace("</p>", "")
//     .replace("</n>", "")
//     .replace("<strong>", "")
//     .replace("</strong>", "")
//     .replace("[&hellip;]", "")
//   return text
// }

function formatDate(data) {
  return data.date
    .slice(0, 10)
    .split("-")
    .reverse()
    .join("-")
}

function getCategories(categories, categoriesSlug) {
  const tags = []
  categories.forEach(function(el, i) {
    if (el === "Bez kategorii") {
      el = null
    } else {
      el = (
        <span>
          <Link to={`kategoria/${categoriesSlug[i]}`}>#{el}</Link>
        </span>
      )
    }
    if (el) {
      tags.push(el)
    }
  })
  return tags
}
