import React from 'react'
import GoogleMapReact from 'google-map-react'
import Marker from './map-marker'

const defaultProps = {
  center: {
    // lat: 53.426,
    // lng: 14.547,
    lat: 53.4261044,
    lng: 14.533,
  },
  zoom: 15,
}

const GoogleMap = () => (
  <div style={{ height: '100%', width: '100%' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyD6YJIAx9fnNv221f1nRmC0X9iCs0X3mKo' }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      <Marker
        lat={53.4261044}
        lng={14.5338564}
        text={'Kancelaria Radcy Prawnego <br /> Anna Milcarz'}
      />
    </GoogleMapReact>
  </div>
)

export default GoogleMap
