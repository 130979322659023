import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"
import primary from "./colors/primary"
import secondary from "./colors/secondary"

export const styles = {
  colorPrimary: primary,
  //colorPrimary: props => (props.theme.mode === "dark" ? "#008ed6" : primary),
  colorSecondary: secondary,
  //colorSecondary: props => props.theme.mode === "dark" ? "#008ed6" : secondary,
  colorDark: "#008ed6",
  colorBlack: "#242526",
  //colorGray: '#7E7E7E',
  colorGray: "#2f3234",
}

// Layout

export const SectionA = styled.section`
  display: ${props => (props.display ? props.display : "block")};
  padding: ${props => margin(props.p ? props.p : "26px 0")};
  position: relative;

  @media (min-width: 576px) {
    display: ${props => (props.displaySM ? props.displaySM : "")};
    padding: ${props => margin(props.pSM ? props.pSM : "")};
  }

  @media (min-width: 768px) {
    display: ${props => (props.displayMD ? props.displayMD : "")};
    padding: ${props => margin(props.pMD ? props.pMD : "")};
  }

  @media (min-width: 992px) {
    display: ${props => (props.displayLG ? props.displayLG : "")};
    padding: ${props => margin(props.pLG ? props.pLG : "")};
  }

  @media (min-width: 1200px) {
    display: ${props => (props.displayXL ? props.displayXL : "")};
    padding: ${props => margin(props.pXL ? props.pXL : "")};
  }
`

export const SectionB = styled.section`
  position: relative;
  overflow: hidden;
  padding: ${props => margin(props.p ? props.p : "0")};

  .MuiContainer-root {
    padding: 0;

    @media (min-width: 576px) {
      padding: 0 16px;
    }
  }

  @media (min-width: 576px) {
    display: ${props => (props.displaySM ? props.displaySM : "")};
    padding: ${props => margin(props.pSM ? props.pSM : "")};
  }

  @media (min-width: 768px) {
    display: ${props => (props.displayMD ? props.displayMD : "")};
    padding: ${props => margin(props.pMD ? props.pMD : "")};
  }

  @media (min-width: 992px) {
    display: ${props => (props.displayLG ? props.displayLG : "")};
    padding: ${props => margin(props.pLG ? props.pLG : "")};
  }

  @media (min-width: 1200px) {
    display: ${props => (props.displayXL ? props.displayXL : "")};
    padding: ${props => margin(props.pXL ? props.pXL : "")};
  }
`

export const FlexBox = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  justify-content: ${props => (props.justify ? props.justify : "flex-start")};
  align-items: ${props => (props.align ? props.align : "flex-start")};
  flex-wrap: ${props => (props.wrap ? props.wrap : "nowrap")};
  flex-grow: ${props => (props.grow ? props.grow : "")};
  width: ${props => (props.width ? props.width : "auto")};
  padding: ${props => margin(props.p ? props.p : "0")};
  margin-bottom: ${props => margin(props.mb ? props.mb : "0")};

  @media (min-width: 768px) {
    flex-direction: ${props => (props.directionMD ? props.directionMD : "")};
    justify-content: ${props => (props.justifyMD ? props.justifyMD : "")};
    align-items: ${props => (props.alignMD ? props.alignMD : "")};
    width: ${props => (props.widthMD ? props.widthMD : "")};
    padding: ${props => margin(props.pMD ? props.pMD : "")};
  }

  @media (min-width: 992px) {
    flex-direction: ${props => (props.directionLG ? props.directionLG : "")};
    justify-content: ${props => (props.justifyLG ? props.justifyLG : "")};
    align-items: ${props => (props.alignLG ? props.alignLG : "")};
    width: ${props => (props.widthLG ? props.widthLG : "")};
    padding: ${props => margin(props.pLG ? props.pLG : "")};
  }
`

// Typography

export const Title1 = styled.h1`
  font-size: 100%;
  line-height: 1.3;
  font-weight: 400;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "kern" off;
  margin-top: 0;
  letter-spacing: 1.6px;
  transition: color .3s ease;

  text-align: ${props => (props.align ? props.align : "center")};
  //color: ${props => (props.color ? props.color : "#fff")};
  color: ${props =>
    props.theme.mode === "dark" ? "#fff" : props.color ? props.color : "#fff"};
  margin-bottom: ${props => margin(props.mb ? props.mb : "0")};
  padding: ${props => margin(props.p ? props.p : "0")};

  span {
    display: block;
    font-weight: 600;
    white-space: nowrap;
  }

  @media (min-width: 576px) {
    font-size: 24px;
    line-height: 1.3;
  }

  @media (min-width: 768px) {
    font-size: 38px;
    line-height: 55px;
  }
`

export const Title2 = styled.h2`
  font-size: 36px;
  line-height: 43px;
  font-weight: 900;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "kern" off;
  margin-top: 0;
  transition: color .3s ease;

  text-align: ${props => (props.align ? props.align : "center")};
  //color: ${props => (props.color ? props.color : styles.colorPrimary)};
  color: ${props =>
    props.theme.mode === "dark"
      ? "#fff"
      : props.color
      ? props.color
      : styles.colorPrimary};
  margin-bottom: ${props => margin(props.mb ? props.mb : "0")};
  padding: ${props => margin(props.p ? props.p : "0")};
`

export const Title3 = styled.h3`
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "kern" off;
  margin-top: 0;
  transition: color .3s ease;

  text-align: ${props => (props.align ? props.align : "center")};
  //color: ${props => (props.color ? props.color : styles.colorPrimary)};
  color: ${props =>
    props.theme.mode === "dark"
      ? "#fff"
      : props.color
      ? props.color
      : styles.colorPrimary};
  margin-bottom: ${props => margin(props.mb ? props.mb : "0")};
  padding: ${props => margin(props.p ? props.p : "0")};
`

export const Text = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  margin-top: 0;
  transition: color 0.3s ease;

  max-width: ${props => (props.maxWidth ? props.maxWidth : "100%")};
  text-align: ${props => (props.align ? props.align : "left")};
  color: ${props =>
    props.theme.mode === "dark" ? "#fff" : props.color ? props.color : "#000"};
  margin-bottom: ${props => margin(props.mb ? props.mb : "0")};
  padding: ${props => margin(props.p ? props.p : "0")};

  @media (min-width: 768px) {
    max-width: ${props => (props.maxWidthMD ? props.maxWidthMD : "")};
    text-align: ${props => (props.alignMD ? props.alignMD : "")};
    padding: ${props => margin(props.pMD ? props.pMD : "")};
  }

  @media (min-width: 992px) {
    max-width: ${props => (props.maxWidthLG ? props.maxWidthLG : "")};
    text-align: ${props => (props.alignLG ? props.alignLG : "")};
    padding: ${props => margin(props.pLG ? props.pLG : "")};
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: ${styles.colorSecondary};
    }
  }

  strong {
    font-weight: 700;
  }
`

export const Link = styled(GatsbyLink)`
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  text-decoration: none;
  transition: color 0.3s ease;

  color: ${props => (props.color ? props.color : styles.colorPrimary)};
  margin-bottom: ${props => margin(props.mb ? props.mb : "0")};

  &:hover {
    color: #000;
  }
`

export const Button = styled(GatsbyLink)`
  color: ${props =>
    props.theme.mode === "dark" ? styles.colorDark : styles.colorSecondary};
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  text-transform: uppercase;
  padding: 8px 20px;
  margin-left: ${props => margin(props.ml ? props.ml : "0")};
  transition: color 0.1s ease, background-color 0.3s ease;

  &:hover {
    color: ${props =>
      props.theme.mode === "dark" ? "#fff" : styles.colorPrimary};

    background-color: ${props =>
      props.theme.mode === "dark" ? styles.colorDark : styles.colorSecondary};
  }
`
//

const margin = margin => {
  if (typeof margin === "string") {
    return margin
  } else if (typeof margin === "number") {
    if (margin === 1) {
      return "3px"
    } else if (margin === 2) {
      return "8px"
    } else if (margin === 3) {
      return "15px"
    } else if (margin === 4) {
      return "30px"
    } else if (margin === 5) {
      return "48px"
    } else if (margin === 6) {
      return "72px"
    } else {
      return "0"
    }
  } else {
    return "0"
  }
}
