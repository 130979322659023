import React from "react"
import { Link } from "gatsby"
import styled, { ThemeConsumer } from "styled-components"
import SVG from "../images/annamilcarz-logo.inline.svg"
import Container from "../components/container"
import { styles } from "./styles"

const Menu = React.lazy(() => import("./menu"))

const Wrapper = styled.header`
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  padding: 21px 0;
  transition: background-color 0.3s ease;

  &.alt {
    position: fixed;
    background-color: #fff;
    background-color: ${props =>
      props.theme === "dark" ? styles.colorBlack : "#fff"};

    .logo {
      a {
        svg {
          path {
            fill: ${props =>
              props.theme === "dark" ? "#fff" : styles.colorSecondary};
            transition: fill 0.3s ease;
          }
        }

        &:hover {
          svg {
            path {
              fill: ${props =>
                props.theme === "dark"
                  ? styles.colorSecondary
                  : styles.colorPrimary};
            }
          }
        }
      }
    }

    .menu {
      a {
        svg {
          path {
            fill: ${styles.colorSecondary};
          }
        }

        &:hover {
          svg {
            path {
              fill: ${styles.colorPrimary};
            }
          }
        }
      }

      button {
        span {
          background-color: ${props =>
            props.theme === "dark" ? "#fff" : styles.colorSecondary};
        }

        &:hover {
          span {
            background-color: ${props =>
              props.theme === "dark" ? styles.colorDark : styles.colorPrimary};
          }
        }
      }

      .toggle-mode {
        svg {
          path {
            fill: ${props =>
              props.theme === "dark" ? "#fff" : styles.colorSecondary};
          }
        }

        &:hover {
          svg {
            path {
              fill: ${props =>
                props.theme === "dark"
                  ? styles.colorDark
                  : styles.colorPrimary};
            }
          }
        }
      }

      ul {
        li {
          a {
            color: ${props =>
              props.theme === "dark" ? styles.colorDark : styles.colorPrimary};
            background-color: ${props =>
              props.theme === "dark" ? "#fff" : styles.colorSecondary};
            border-color: ${props =>
              props.theme === "dark" ? "#fff" : styles.colorSecondary};

            &:hover {
              color: #fff;
              background-color: ${props =>
                props.theme === "dark"
                  ? styles.colorDark
                  : styles.colorPrimary};
              border-color: ${props =>
                props.theme === "dark"
                  ? styles.colorDark
                  : styles.colorPrimary};
            }
          }
        }
      }
    }
  }
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  z-index: 12;
`

const Logo = styled.div`
  width: 130px;
  width: 45vw;
  max-width: 270px;
`

const StyledLink = styled(Link)`
  svg {
    path {
      fill: #fff;
      transition: fill 0.3s ease;
    }
  }

  &:hover {
    svg {
      path {
        fill: ${props =>
          props.theme === "dark" ? styles.colorDark : styles.colorSecondary};
      }
    }
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Header = () => {
  const isSSR = typeof window === "undefined"
  return (
    <ThemeConsumer>
      {theme => (
        <Wrapper className={"header"} theme={theme.mode}>
          <Content>
            <Container>
              <Flex>
                <LogoContainer>
                  <Logo className={"logo"}>
                    <StyledLink to={"/"} theme={theme.mode}>
                      <SVG />
                    </StyledLink>
                  </Logo>
                </LogoContainer>
                {!isSSR && (
                  <React.Suspense fallback={<div />}>
                    <Menu />
                  </React.Suspense>
                )}
              </Flex>
            </Container>
          </Content>
        </Wrapper>
      )}
    </ThemeConsumer>
  )
}

export default Header
