/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { ThemeProvider, createGlobalStyle } from "styled-components"
import { styles } from "./styles"
import Header from "./header"
import Footer from "./footer"
import useTheme from "./use-theme"
import "./layout.css"

const GlobalStyle = createGlobalStyle`
body {
  background-color: ${props =>
    props.theme.mode === "dark" ? "#242526" : "#fff"};
  transition: background-color .3s ease;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: ${props =>
    props.theme.mode === "dark" ? "#242526" : "#fff"};
}

::-webkit-scrollbar {
  width: 12px;
  background-color: ${props =>
    props.theme.mode === "dark" ? "#242526" : "#fff"};
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: ${styles.colorSecondary};
  opacity: 0.6;
}
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  * {
    &::selection {
      background-color: ${styles.colorSecondary};
      color: #000;
    }
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header siteTitle={data.site.siteMetadata.title} />
      <Main>{children}</Main>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
