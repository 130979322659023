import React from "react"
import styled from "styled-components"
import { styles, Text, FlexBox } from "./styles"
import GoogleMap from "./map"
import Container from "./container"
import MapIcon from "../images/map.inline.svg"
import LogoIcon from "../images/annamilcarz-logo.inline.svg"

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const MapWrapper = styled.div`
  width: 100%;
  position: relative;

  @media (min-width: 992px) {
    height: 364px;
  }
`

const Map = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 307px;
  width: 100%;
  // max-height: 307px;
  // height: 16vw;
  overflow: hidden;
`

const Box = styled(FlexBox)`
  height: 364px;
  width: 100%;
  max-width: 100%;
  background-color: ${styles.colorPrimary};
  position: relative;

  @media (min-width: 992px) {
    position: absolute;
    top: 0;
    left: 24px;
    width: 30vw;
    max-width: 421px;
  }

  @media (min-width: 1366px) {
    left: 32px;
  }
`

const MapIconWrapper = styled.a`
  height: 90px;
  width: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    path {
      fill: ${props =>
        props.theme.mode === "dark" ? "#fff" : styles.colorSecondary};
    }
  }
`

const LogoIconWrapper = styled.div`
  width: 100%;
  padding-left: 36px;
  max-width: 500px;
  margin: 0 auto;
`

const ListBox = styled.div`
  border-left: 4px solid ${styles.colorSecondary};
  padding-left: 35px;

  &:not(:nth-last-child(1)) {
    padding-bottom: 25px;
  }

  @media (min-width: 992px) {
    &:not(:nth-last-child(1)) {
      padding-bottom: 0;
    }
  }
`

const List = styled.div`
  list-style: none;
  padding: 0;
  margin: 0;
`

const ListItem = styled.div``

const Copyright = styled(Text)`
  font-weight: 400;
  font-size: 10px;
  line-height: 1;
  color: ${styles.colorPrimary};
`

const Author = styled.a`
  color: ${styles.colorPrimary};
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: ${styles.colorSecondary};
  }
`

const BoxContainer = styled(Container)`
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;

  @media (min-width: 600px) {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: 992px) {
    max-width: 90%;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: 1366px) {
    max-width: 1200px;
    padding-left: 32px;
    padding-right: 32px;
  }
`

const Footer = () => {
  return (
    <Wrapper>
      <MapWrapper>
        <BoxContainer>
          <Box align={"flex-end"}>
            <MapIconWrapper
              href={
                "https://www.google.com/maps/place/Kaszubska+57,+71-899+Szczecin,+Polska/@53.4250124,14.5446182,15.5z/data=!4m5!3m4!1s0x47aa0910b712b0a9:0x1f4bdf7192ee665!8m2!3d53.425926!4d14.5493909?hl=pl-PL"
              }
              target="_blank"
              title={"Otwórz mapę w nowym oknie"}
            >
              <MapIcon />
            </MapIconWrapper>
            <LogoIconWrapper>
              <LogoIcon />
            </LogoIconWrapper>
          </Box>
        </BoxContainer>
        <Map>
          <GoogleMap />
        </Map>
      </MapWrapper>
      <Container>
        <FlexBox
          direction={"column"}
          directionLG={"row"}
          justify={"space-between"}
          p={"36px 0"}
        >
          <ListBox>
            <List>
              <ListItem>
                <Text>Kancelaria Radcy Prawnego Anna Milcarz</Text>
              </ListItem>
              <ListItem>
                <Text>NIP 6711673133</Text>
              </ListItem>
            </List>
          </ListBox>
          <ListBox>
            <List>
              <ListItem>
                <Text>ul. Pułaskiego 1/5</Text>
              </ListItem>
              <ListItem>
                <Text>70-322 Szczecin</Text>
              </ListItem>
            </List>
          </ListBox>
          <ListBox>
            <List>
              <ListItem>
                <Text>
                  tel.: <a href="tel:48693975699">693 975 699</a>
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  e-mail:{" "}
                  <a href="mailto:radca.annamilcarz@gmail.com<">
                    radca.annamilcarz@gmail.com
                  </a>
                </Text>
              </ListItem>
            </List>
          </ListBox>
        </FlexBox>
      </Container>
      <Copyright align={"center"} p={"0 0 15px"}>
        © {new Date().getFullYear()}, ALL RIGHTS RESERVED. DESIGNED & CODED BY
        {` `}
        <Author
          href="https://www.facebook.com/webscris"
          target="_blank"
          rel="noreferrer"
        >
          CRIS
        </Author>
      </Copyright>
    </Wrapper>
  )
}

export default Footer
