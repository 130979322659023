import { graphql, useStaticQuery } from "gatsby"

const Color = () => {
  const colorPrimaryQuery = useStaticQuery(graphql`
    query colorPrimaryQuery {
      allWordpressAcfPages(filter: { wordpress_id: { eq: 2 } }) {
        nodes {
          id
          acf {
            ustawienia_kolor_przewodni
          }
        }
      }
    }
  `)

  const acf = colorPrimaryQuery.allWordpressAcfPages.nodes[0].acf

  return acf.ustawienia_kolor_przewodni
}

export default Color
